import './NavigationMenu.css';

import {slide as Menu} from 'react-burger-menu';
import {Link} from 'react-router-dom';

function NavigationMenu() {
  const burgerMenuStyles = {
    bmItemList: {
      height: "90%",
    },
  };

  return (
      <Menu styles={burgerMenuStyles}>
        <Link to="/">Home</Link>
        <Link to="/rules">Rules</Link>
      </Menu>
  );
}

export default NavigationMenu;
