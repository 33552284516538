import './Common.css';

function Rules() {
  return (
    <div>
      <div className="Common-header">
        <p className="Common-text">
	  <ul>
  	    <li>Bring four different beverages</li>
	    <li>Beers, ciders, seltzers, and meads are eligible for entry (wine and spirits are not eligible)</li>
	    <li>Bring at least 32 ounces of each</li>
	    <li>Everyone tries each entry and rates it on a scale from zero to five, in quarter-point increments</li>
	    <li>The beverage with the best average rating wins one cup</li>
	    <li>Whoever brings the four beverages with the highest average rating wins one cup</li>
	  </ul>
        </p>
      </div>
    </div>
  );
}

export default Rules;
