import NavigationMenu from './NavigationMenu';
import {Outlet} from 'react-router-dom';

function Root() {
  return (
      <div>
        <NavigationMenu/>
        <main>
          <Outlet/>
        </main>
      </div>
  );
}

export default Root;
