import './Common.css';

function Home() {
  return (
      <div className="Common-header">
	<p className="Common-text">
	  The Fourth Annual Greater South Aurora Regional Beer Festival
	</p>
        <p className="Common-text">
          Saturday, 30 September 2023
        </p>
      </div>
  );
}

export default Home;
