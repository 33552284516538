import Home from './Home';
import Root from './Root';
import Rules from './Rules';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';

const router = createBrowserRouter([
    {path: "/", element: <Root/>,
        children: [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "/rules",
                element: <Rules/>,
            },
        ],
    },
]);

function App() {
  return <RouterProvider router={router}/>;
}

export default App;
